import React, { useEffect, useRef } from 'react';
import './Home.css';
import videoImg from './images/home/home-image1.png';
import abbyMuglia from './images/headshots/abby_muglia.jpeg';
import serviceImg from './images/home/home-service-pic.jpeg';
import inclusionImg from './images/home/home-inclusion-pic.jpeg';
import leadershipImg from './images/home/home-leadership-pic.png';
import unityImg from './images/home/home-unity-pic.jpeg';
import curiosityImg from './images/home/home-curiosity-pic.jpg';

// Import logos for companies section
import abercrombie from './images/home/home-company-logos/abercrombie.png';
import accenture from './images/home/home-company-logos/accenture.png';
import amazon from './images/home/home-company-logos/amazon.png';
import bcg from './images/home/home-company-logos/bcg.png';
import bofa from './images/home/home-company-logos/bofa.png';
import capone from './images/home/home-company-logos/capital-one.png';
import deloitte from './images/home/home-company-logos/deloitte.png';
import ey from './images/home/home-company-logos/ey.png';
import gap from './images/home/home-company-logos/gap.png';
import goldman from './images/home/home-company-logos/goldman.png';
import honda from './images/home/home-company-logos/honda.png';
import jpm from './images/home/home-company-logos/jp-morgan.png';
import kroger from './images/home/home-company-logos/kroger.png';
import levis from './images/home/home-company-logos/levis.png';
import mckinsey from './images/home/home-company-logos/mckinsey.png';
import microsoft from './images/home/home-company-logos/microsoft.png';
import nationwide from './images/home/home-company-logos/nationwide.png';
import oracle from './images/home/home-company-logos/oracle.png';
import pwc from './images/home/home-company-logos/pwc.png';
import spacex from './images/home/home-company-logos/spacex.png';
// Add more imports as needed

export default function Home() {
    const videoUrl = 'https://s3.us-east-2.amazonaws.com/bpadohiostate.com/home-drone-shot.mp4';
    const videoRef = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);
    const pillarsRef = useRef(null);
    const pillarsSectionRef = useRef(null);
    const pillarRefs = useRef([]);
    const logoRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (imageRef.current) {
                        imageRef.current.classList.add('fade-in');
                    }
                }
            },
            {
                threshold: 0.1, // Trigger when 10% of the image is visible
            }
        );

        const textObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (textRef.current) {
                        textRef.current.classList.add('fade-in');
                    }
                }
            },
            {
                threshold: 0.1, // Trigger when 10% of the text is visible
            }
        );

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        if (textRef.current) {
            textObserver.observe(textRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.observe(videoRef.current);
            }
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
            if (textRef.current) {
                textObserver.unobserve(textRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (pillarsRef.current && pillarsSectionRef.current) {
                const scrollTop = window.pageYOffset;
                const textSpeed = 0.5; // Adjust this value for desired text speed
                const bgSpeed = 0.2; // Adjust this value for desired background speed
                pillarsRef.current.style.transform = `translateY(${scrollTop * textSpeed}px)`;
                pillarsSectionRef.current.style.backgroundPositionY = `${scrollTop * bgSpeed}px`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const pillarObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in');
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        pillarRefs.current.forEach(pillar => {
            if (pillar) {
                pillarObserver.observe(pillar);
            }
        });

        return () => {
            pillarRefs.current.forEach(pillar => {
                if (pillar) {
                    pillarObserver.unobserve(pillar);
                }
            });
        };
    }, []);

    useEffect(() => {
        const logoObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in');
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        logoRefs.current.forEach(logo => {
            if (logo) {
                logoObserver.observe(logo);
            }
        });

        return () => {
            logoRefs.current.forEach(logo => {
                if (logo) {
                    logoObserver.unobserve(logo);
                }
            });
        };
    }, []);

    const handleScrollDown = () => {
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="video-section">
            <video
                ref={videoRef}
                autoPlay
                loop
                muted
                playsInline
                disablePictureInPicture
                onTouchStart={(e) => e.preventDefault()} // Prevent zooming when tapping the video
            >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <div className="text-content">
                        <h2>BUCKEYE PROFESSIONAL ADVANCEMENT & DEVELOPMENT</h2>
                        <div className="container">
                            <p className="typewriter">Developing The Leaders Of Tomorrow.</p>
                        </div>
                    </div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdtSG9BdExP95j2XnYQCm8jhElfShGBuxUFf-fuVO1bddPlkA/viewform" target="_blank" class="recruitment-button">SPRING 2025 RECRUITMENT</a>
                </div>
                <div className="scroll-arrow" onClick={handleScrollDown}>
                    <span className="arrow"></span>
                    <span className="scroll-text">SCROLL DOWN</span>
                </div>
            </div>
            <div className="home-section">
                <div className="home-content">
                    <img src={abbyMuglia} alt="Abby Muglia" className="home-image" ref={imageRef} />
                    <div className="home-text" ref={textRef}>
                        <h2>A FEW WORDS FROM THE PRESIDENT</h2>
                        <p>
                        On behalf of the members of Buckeye Professional Advancement and Development, we want to thank you for visiting our website. BPAD has established itself as one of the premier student organizations at Ohio State and it is with great excitement and pride that we invite you to explore our organziation.
                        </p>
                        <p>
                        Our membership is composed of some of the most talented and accomplished students at Ohio State from a wide range of professional aspirations. We accept students from all majors and aspire to help students grow to their career path of interest. Our organization is home to the upstanding leaders of tomorrow who are dedicated to pursing their personal and professional goals.
                        </p>
                        <p>
                        Since becoming a member of BPAD, I have found a community that has not only imparted me with valuable professional guidance, but also friendships that have become an integral part of my Ohio State experience. BPAD offers endless experiences, opportunities, and friendships – and I encourage you to continue your exploration by attending our recruitment events. Thank you for visiting our website and we look forward to the opportunity to connect with you!
                        </p>
                        <p>
                            Sincerely,
                            <p>Abby Muglia</p>
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="our-pillars-section" ref={pillarsSectionRef}>
                <div className="our-pillars-content" ref={pillarsRef}>
                    <h2>OUR PILLARS</h2>
                    <p>Here at BPAD, we are guided by our five pillars.</p>
                </div>
            </div>
            <div className="pillars-section">
                <div className="pillar pillar-left pillar-service" ref={el => pillarRefs.current[0] = el}>
                    <img src={serviceImg} alt="Service" className="pillar-image" />
                    <div className="pillar-text">
                    <h3>SERVICE</h3>
                    </div>
                </div>
                <div className="pillar pillar-right pillar-inclusion" ref={el => pillarRefs.current[1] = el}>
                    <img src={inclusionImg} alt="Inclusion" className="pillar-image" />
                    <div className="pillar-text">
                    <h3>INCLUSION</h3>
                    </div>
                </div>
                <div className="pillar pillar-left pillar-leadership" ref={el => pillarRefs.current[2] = el}>
                    <img src={leadershipImg} alt="Leadership" className="pillar-image" />
                    <div className="pillar-text">
                    <h3>LEADERSHIP</h3>
                    </div>
                </div>
                <div className="pillar pillar-right pillar-unity" ref={el => pillarRefs.current[3] = el}>
                    <img src={unityImg} alt="Unity" className="pillar-image" />
                    <div className="pillar-text">
                    <h3>UNITY</h3>
                    </div>
                </div>
                <div className="pillar pillar-left pillar-curiosity" ref={el => pillarRefs.current[4] = el}>
                    <img src={curiosityImg} alt="Curiosity" className="pillar-image" />
                    <div className="pillar-text">
                    <h3>CURIOSITY</h3>
                    </div>
                </div>
            </div>
            <div className="companies-section">
                <div className="separator"></div>
                <h2>COMPANIES WE'VE BROUGHT VALUE TO</h2>
                <div className="logos-container">
                    <img src={abercrombie} alt="Abercrombie" className="company-logo" ref={el => logoRefs.current[0] = el} />
                    <img src={accenture} alt="Accenture" className="company-logo" ref={el => logoRefs.current[1] = el} />
                    <img src={amazon} alt="Amazon" className="company-logo" ref={el => logoRefs.current[2] = el} />
                    <img src={bcg} alt="BCG" className="company-logo" ref={el => logoRefs.current[3] = el} />
                    <img src={bofa} alt="BofA" className="company-logo" ref={el => logoRefs.current[4] = el} />
                    <img src={capone} alt="Capital One" className="company-logo" ref={el => logoRefs.current[5] = el} />
                    <img src={deloitte} alt="Deloitte" className="company-logo" ref={el => logoRefs.current[6] = el} />
                    <img src={ey} alt="EY" className="company-logo" ref={el => logoRefs.current[7] = el} />
                    <img src={gap} alt="GAP" className="company-logo" ref={el => logoRefs.current[8] = el} />
                    <img src={goldman} alt="Goldman" className="company-logo" ref={el => logoRefs.current[9] = el} />
                    <img src={honda} alt="Honda" className="company-logo" ref={el => logoRefs.current[10] = el} />
                    <img src={jpm} alt="JP Morgan" className="company-logo" ref={el => logoRefs.current[11] = el} />
                    <img src={kroger} alt="Kroger" className="company-logo" ref={el => logoRefs.current[12] = el} />
                    <img src={levis} alt="Levis" className="company-logo" ref={el => logoRefs.current[13] = el} />
                    <img src={mckinsey} alt="Mckinsey" className="company-logo" ref={el => logoRefs.current[14] = el} />
                    <img src={microsoft} alt="Microsoft" className="company-logo" ref={el => logoRefs.current[15] = el} />
                    <img src={nationwide} alt="Nationwide" className="company-logo" ref={el => logoRefs.current[16] = el} />
                    <img src={oracle} alt="Oracle" className="company-logo" ref={el => logoRefs.current[17] = el} />
                    <img src={pwc} alt="PWC" className="company-logo" ref={el => logoRefs.current[18] = el} />
                    <img src={spacex} alt="Space X" className="company-logo" ref={el => logoRefs.current[19] = el} />
                </div>
            </div>
        </>
    );
}
