import React, { useRef, useEffect } from 'react';
import './Join.css';
import joinImage from './images/join/join-image1.jpg'; // Background image
import joinImage2 from './images/join/join-events-image.jpeg';
import eberry from './images/headshots/elizabeth_berry.jpeg';

const Join = () => {
    const backgroundImageRef = useRef(null);

    const handleScrollDown = () => {
        const joinContentSection = document.querySelector('.join-content-section');
        if (joinContentSection) {
            joinContentSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="join-page">
            <div className="join-section" style={{ backgroundImage: `url(${joinImage})` }} ref={backgroundImageRef}>
                <div className="join-text">
                    <h1>JOIN US</h1>
                    <p>Become a part of our community.</p>
                </div>
                <div className="scroll-arrow" onClick={handleScrollDown}>
                    <span className="arrow"></span>
                    <span className="scroll-text">SCROLL DOWN</span>
                </div>
            </div>
            <div className="join-content-section">
                <div className="recruitment-season">
                    <h2>SPRING 2025 RECRUITMENT</h2>
                    <h1>_____</h1>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_D0jV5_PyMrYTnnBWR7iTFgZKRXJvdudo3PJzKyh5veWFiQ/viewform" target="_blank" className="recruitment-button">APPLY NOW</a>
                </div>
                <img src={joinImage2} alt="Events" className="events-image"/> 
                {/* General Events */}
                <div className="event-box-container general">
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A194550" target="_blank" className="event-box">
                        <h3>INFO SESSION 1</h3>
                        <p>January 21st 7pm-9pm</p>
                        <p>Hitchcock Hall #131</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A194550" target="_blank" className="event-box">
                        <h3>INFO SESSION 2</h3>
                        <p>January 22nd 7pm-9pm</p>
                        <p>Hitchcock Hall #131</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A539" target="_blank" className="event-box">
                        <h3>MEMBER MINGLE</h3>
                        <p>January 23rd 6pm-8pm</p>
                        <p>Independence #100</p>
                    </a>
                </div>

                {/* Invite Only Events */}
                <div className="event-box-container invite">
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A15345" target="_blank" className="event-box">
                        <h3>*PROFESSIONAL EVENT</h3>
                        <p>January 26th 2pm-4pm</p>
                        <p>Blackburn Victory Room</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A15345" target="_blank" className="event-box">
                        <h3>*SERVICE EVENT</h3>
                        <p>January 27th 3:30pm-5:30pm</p>
                        <p>Blackburn Victory Room</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A506" target="_blank" className="event-box">
                        <h3>*INTERVIEWS</h3>
                        <p>January 28th - January 31st</p>
                        <p>Ohio Union KBK Rooms</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_medium=CampusMapRedirect&utm_content=0274#data_s=id%3AdataSource_22-1916b96cc76-layer-20-1%3A194550" target="_blank" className="event-box">
                        <h3>*LAST MINUTE MEET & GREET</h3>
                        <p>February 2nd 10:30am-11:30am</p>
                        <p>Hitchcock Hall #031</p>
                    </a>
                </div>
                <div className="asterisk">
                    <h2>*Invite Only Events</h2>
                </div>
                <div className="questions-comments">
                    <div className="separator"></div>
                    <h2>QUESTIONS, COMMENTS, CONCERNS?</h2>
                    <p>Please reach out to our Logistical Recruitment Director for assistance:</p>
                    <a href="https://www.linkedin.com/in/elizabeth-berry-9a6a59260/" target="_blank" rel="noopener noreferrer">
                        <img src={eberry} alt="Elizabeth Berry" className="director-image"/>
                    </a>
                    <p className="director-name">Elizabeth Berry</p>
                    <p className="director-email">berry.906</p>
                </div>

            </div>
        </div>
    );
};

export default Join;
